import MyEmitter from "events";
import Swal from "sweetalert2";
import storage from "../../../Storage";
import socket from "../../../Socket";
import C from "../../../Constant";
import { Event, forceSatoshiFormat, encode, decode } from "../../../Helper";

/**
 * Game Engine
 */
function Engine() {
  let self = this;

  /**
   * Game Name
   */
  self.game = "limbo";

  /**
   * Socket Connection
   */
  self.ws = socket;

  /**
   * Event Trigger
   * @type {module:events.internal}
   */
  self.trigger = new MyEmitter();

  /**
   * Itialize Game
   */
  self.init = false;

  /**
   * Game Status
   * 1 type: busted_limbo
   */
  self.gameStatus = null;

  /**
   * Current User id
   */
  self.token = null;

  /**
   * Round Amount
   */
  self.amount = null;

  /**
   * Round Payout
   */
  self.payout = null;

  /**
   * Player Coin
   */
  self.coin = null;

  /**
   * Bet Number
   */
  self.betNumber = null;

  /**
   * Increase Amount on Win
   */
  self.onWin = 0;

  /**
   * Increase Amount on Lost
   */
  self.onLost = 0;

  /**
   * Played Game Rounds
   */
  self.played = 0;

  /**
   * Player is the Win or Lost
   */
  self.isWin = false;

  /**
   * Stop Playing if Won Amount
   */
  self.stopOnWin = 0;

  /**
   * Round Profit
   */
  self.roundProfit = 0;

  /*
   * Start Engine
   */
  self.started = true;

  if (self.started) {
    self.ws.on(C.PLAY_LIMBO, (data) => {
      data = decode(data);

      switch (data.command) {
        case "play":
          return self.trigger.emit(C.PLAY_LIMBO, data);
        case "busted":
          return self.trigger.emit(C.BUSTED_LIMBO, data);
        case "error":
          return self.trigger.emit(C.ERROR_LIMBO, data);
      }
    });
  }
}

Engine.prototype.off = function () {
  let self = this;
  self.ws.off(C.PLAY_LIMBO);
};

Engine.prototype.on = function (name, callback) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.on(name, (data) => callback(data));
};

Engine.prototype.emit = function (name, data = []) {
  let self = this;
  if (!self.started) return;
  if (self.trigger === null) return;
  return self.trigger.emit(name, data);
};

/**
 * Add Player for playing game
 */
Engine.prototype.start = function () {
  let self = this;

  if (!self.init) return;

  if (self.amount < 0.0000001) {
    self.amount = forceSatoshiFormat(0.0000001);
  }
//changes by me 148  to 170
  if(parseFloat(storage.getKey('credit')) === parseFloat(self.amount)){
      return Swal.fire({
                  title: 'Are you sure to play with all your credits ?',
                  text: "",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes!'
              }).then((result) => {
              if (result.value) {
                  self.ws.emit(C.PLAY_LIMBO, encode({
                      token: self.token,
                      amount: self.amount,
                      payout: self.payout,
                      coin: self.coin
                  }));
              }
              else {
                  self.trigger.emit('stop_playing');
              }
      });
  }

  self.ws.emit(
    C.PLAY_LIMBO,
    encode({
      token: self.token,
      amount: self.amount,
      payout: self.payout,
      coin: self.coin,
    })
  );
};

/**
 * Auto Start
 */
Engine.prototype.autoStart = function () {
  let self = this;

  if (!self.init) return;

  self.played++;

  if (parseInt(self.betNumber) <= 0) {
    self.played = 0;
    self.trigger.emit("stop_limbo");
    return;
  }

  // Stop On Bet Number
  if (self.played === parseInt(self.betNumber)) {
    self.played = 0;
    self.trigger.emit("stop_limbo");
    return;
  }

  // Increase Amount on Win (player set increase amount on win in %)
  // if(self.onWin !== 0)
  // {
  //     // It Mean Player has win in this round
  //     if(self.isWin)
  //     {
  //         self.amount *= parseInt(self.onWin);
  //     }
  // }

  // // Increase Amount on Lost (player set increase amount on lost in %)
  // if(self.onLost !== 0)
  // {
  //     // It Mean Player has lost in this round
  //     if(!self.isWin)
  //     {
  //         self.amount *= parseInt(self.onLost);
  //     }
  // }

  //Stop On Win (player set amount to stop on win)
  if (self.stopOnWin !== 0) {
    if (
      forceSatoshiFormat(self.stopOnWin) >= forceSatoshiFormat(self.roundProfit)
    ) {
      self.played = 0;
      self.roundProfit = 0;
      self.trigger.emit("stop_limbo");
      return;
    }
  }

  if (self.amount < 0.0000001) {
    self.amount = forceSatoshiFormat(0.0000001);
  }

  self.ws.emit(
    C.PLAY_LIMBO,
    encode({
      token: self.token,
      amount: self.amount,
      payout: self.payout,
      coin: self.coin,
    })
  );
};

/** Start Game Engine **/
export default Engine;
