import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setPlay } from "../../../actions/gamePlay";
import { setClassicDicePayout, setClassicDiceChance, setClassicDiceType } from "../../../actions/gameDice";
import $ from "jquery";
import ionRangeSlider from "ion-rangeslider";
import { isValidNumber, wait, isMobile, playAudio } from "../../../Helper";
import storage from "../../../Storage";

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            margin: 'mt-2',
            marginBottom: 'mb-1',
            under_over: (50).toFixed(2),
            win_chance: (50).toFixed(2),
            payout: (1.98).toFixed(4),
            px: null,
            type: "Under",
            currentRange: 50,
            over_value: 50,
            under_value: 50,
            style: ''
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        //Prevent For Error, IMPORTANT
        this.props.setPlay(false);

        // Redux set Play
        if (nextProps.classic_dice_result) {
            this.playDice(nextProps.classic_dice_result);
        }
    }

    handleResize() {
        if (isMobile()) {
            this.setState({ margin: "mt-2", marginBottom: 'mb-0', px: 'px-1', style: '#fd6638' });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.props.setClassicDiceChance(50);
        this.props.setClassicDicePayout(1.9800);
        this.props.setClassicDiceType("Under");

        wait(300).then(() => {
            this.handleResize();
        });

        let self = this;

        $("#range").ionRangeSlider({
            type: "single",
            skin: "big",
            min: 0,
            max: 100,
            from: 50,
            grid: true,
            from_min: 2,
            from_max: 98,
            onChange: function (data) {
                playAudio('dice.mp3')
                let value = parseFloat(data.from);
                self.props.setClassicDiceChance(value);
                self.setState({ currentRange: value })
                wait(50).then(() => {
                    self.calculateFields(value);
                })
            }
        });

        let dice = document.querySelector('.dice-result .roll');
        dice.style.transform = `translateX(${50}%)`;
    }

    handleInputChange = (e) => {
        let target = e.target;
        let value = target.value;
        let name = target.name;
        if (!isValidNumber(value)) return;

        if (name === 'win_chance' || name === 'under_over') {
            if (parseFloat(value) > 98) return;
            if (parseFloat(value) < 2) return;

            value = parseFloat(value)

            this.setState({ 'win_chance': value.toFixed(2) });
            this.setState({ 'under_over': value.toFixed(2) });

            if (this.state.type === "Over")
                this.setState({ over_value: value.toFixed(2) });
            else
                this.setState({ under_value: value.toFixed(2) });
        }
    };

    playDice = (val) => {
        $('.irs-to').html(val);
        let dice = document.querySelector('.dice-result .roll');
        let instanceText = document.querySelector('.dice-result span');
        instanceText.style.display = 'block';
        instanceText.innerText = val.toFixed(2) + 'x';
        let fix = 7;
        val = parseFloat(val);

        if (isMobile()) {
            if (val < 10) {
                val = 11;
            }
            else if (val > 90) {
                fix = 0;
                val = 90;
            }
        }
        else {
            if (val > 90) {
                fix = 2;
                val = 90;
            }
        }

        dice.style.transform = `translateX(${val + fix}%)`;
    };

    rollChange = () => {
        let { type, currentRange } = this.state;
        var val = currentRange;

        if (type === "Under") {
            this.props.setClassicDiceType("Over");
            this.setState({ type: "Over" });
            val = 100 - currentRange;
        }
        else {
            this.props.setClassicDiceType("Under");
            this.setState({ type: "Under" });
            val = currentRange;
        }

        wait(1).then(() => {
            this.setSlide(val);
            this.calculateFields(val);
        })
    };

    setSlide = (val) => {
        let instance = $("#range").data("ionRangeSlider");
        instance.update({ from: val });
    };

   
    // calculateFields = (value) => {
    //     let { type, over_value, currentRange } = this.state;
    //     this.setState({ under_over: value.toFixed(2) });
    //     let chance = this.state.type === "Over" ? 100 - value : value;
    //     this.setState({ win_chance: chance.toFixed(2) });

    //     this.setState({ over_value: value.toFixed(2), under_value: value.toFixed(2) });

    //     var max = type === "Under" ? 100 : 150;
    //     var min = 0;

    //     if (value < 48) {
    //         min = .020;
    //     }
    //     else min = .020;

    //     var payout = max / value - min;

    //     if (type === "Over") {
    //         payout = value / 2 + min;
    //     }

    // *** Updated calculateFields Method ***

    calculateFields = (value) => {

        let { type } = this.state;

        this.setState({ under_over: value.toFixed(2) });



        // Calculate win chance based on type

        let chance = type === "Over" ? (100 - value) : value;

        this.setState({ win_chance: chance.toFixed(2) });



        // Update over and under values

        this.setState({ 

            over_value: value.toFixed(2), 

            under_value: value.toFixed(2) 

        });



        // Set max to 100 for both "Under" and "Over" to maintain symmetry

        var max = 100; 

        var min = 0.020; // Simplified min assignment



        var payout;



        if (type === "Under") {

            // Payout calculation for "Under"

            payout = max / value - min;

        } else if (type === "Over") {

            // Ensure value is not 100 to avoid division by zero

            if (value === 100) {

                payout = Infinity; // Or handle appropriately

            } else {

                // Reciprocal payout calculation for "Over"

                payout = max / (max - value) - min;

            }

        }
        this.setState({ payout: payout.toFixed(4) });
        this.props.setClassicDicePayout(parseFloat(payout.toFixed(4)));
        this.props.setClassicDiceChance(value);
    }

    render() {
        let { margin, marginBottom, px, type } = this.state;

        return (
            <>
                <Row>
                    <Col sm={12} md={12} className={'m-auto'}>
                        <Card className={'my-2 ras py-0 bg-dice-options'}>
                            <Card.Body className={px + ' ' + type}>
                                <div className="dice-result">
                                    <div className="roll">
                                        <img id="cDice" className="img-fluid" src="/assets/images/dice.png" />
                                        <span>50.00x</span>
                                    </div>
                                </div>
                                <input type="text" id="range" />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12} className={'m-auto'}>
                        <Card className={marginBottom + ' bg-dice-options'}>
                            <Card.Body className={px + ' g-dice py-0'}>
                                <Row>
                                    <Col sm={12} md={10} className={margin + ' mx-auto'}>
                                        <Row>
                                            <Col md={4} className={'col-4'}>
                                                <div className={"form-group cashout"}>
                                                    <div className="form-group text-left">
                                                        <label htmlFor={'payout'}>
                                                            Payout
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                readOnly={true}
                                                                className="form-control"
                                                                id="payout"
                                                                name="payout"
                                                                value={this.state.payout}
                                                                autoComplete={"off"} />
                                                            <div className="input-group-append hidden-sm">
                                                                <span className="input-group-text  font-19">
                                                                    <i className="mdi mdi-close" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4} className={'col-4'}>
                                                <div className={"form-group text-left"}>
                                                    <label onClick={this.rollChange} htmlFor={'win_chance'}>
                                                        Roll {type}
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            readOnly={true}
                                                            style={{ background: this.state.style }}
                                                            className="form-control cpt"
                                                            id="under_over"
                                                            name="under_over"
                                                            value={this.state.under_over}
                                                            // onClick={this.rollChange}
                                                        />
                                                        <div className="input-group-append cpt hidden-sm" onClick={this.rollChange}>
                                                            <span className="input-group-text bg-danger-3">
                                                                <i className={"mdi mdi-rotate-3d font-15 Over-" + type} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4} className={'col-4'}>
                                                <div className={"form-group text-left"}>
                                                    <label htmlFor={'win_chance'}>
                                                        <span className={'hidden-sm'}>Win</span> Chance
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            readOnly={true}
                                                            className="form-control"
                                                            id="win_chance"
                                                            name="win_chance"
                                                            value={this.state.win_chance}
                                                            autoComplete={"off"} />
                                                        <div className="input-group-append hidden-sm">
                                                            <span className="input-group-text font-19">
                                                                %
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

Game.propTypes = {
    setPlay: PropTypes.func,
    play: PropTypes.bool,
    classic_dice_result: PropTypes.number,
    classic_dice_chance: PropTypes.number
};

const mapStateToProps = state => ({
    play: state.items.play,
    classic_dice_result: state.items.classic_dice_result,
    classic_dice_chance: state.items.classic_dice_chance,
});

export default connect(mapStateToProps, { setPlay, setClassicDicePayout, setClassicDiceChance, setClassicDiceType })(Game);