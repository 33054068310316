import * as PIXI from "pixi.js";
import { Bodies, Body, Composite, Engine, Events, World } from "matter-js";
import { playAudio, wait, rand, forceSatoshiFormat } from "../../../../Helper";

PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;

class Game {
    constructor() {
        this.app = new PIXI.Application({
            width: 500,
            height: 500,
            resolution: 1,
            transparent: true,
            view: document.getElementById('game')
        });

        this.bockets = []
        this.balls = []
        this.explosion = []
        this.bocketAnimation = []
        this.started = false
        this.game_engine = null
        this.scores = [5.6, 2.1, 1.1, 0.5, 0.0, 0.5, 1.1, 2.1, 5.6];
    }

    init(eng) {
        this.game_engine = eng;
        this.engine = Engine.create();
        Engine.run(this.engine);
        Events.on(this.engine, 'collisionStart', (e) => this.collisionStart(e, this));
        this.engine.world.gravity.y = 1.1

        this.container = new PIXI.Container();
        this.app.stage.addChild(this.container);

        this.app.loader.add('spritesheet', '/spritesheet/mc.json').load(e => this.load());

        this.addPlinko()
        this.addBocket()
    }

    load() {
        let count = 0
        this.app.ticker.add((delta) => this.animation());
    }

    animation() {
        if (this.started) {
            let bodies = Composite.allBodies(this.engine.world);
            bodies.forEach((body, i) => {
                if (body.label === 'ball') {
                    this.balls[body.id].x = body.position.x
                    this.balls[body.id].y = body.position.y
                }
            });
        }
    }

    collisionStart({
        pairs
    }, timestamp, source, name) {
        pairs.forEach(({
            bodyA,
            bodyB
        }) => {
            [bodyA, bodyB].forEach(body => {

                if (body.label === 'pin') {
                    playAudio('ding.mp3');
                }

                if (body.label === 'bocket') {
                    this.sendScore(body.score)
                    playAudio('win.mp3');
                    this.bocketAnimation.push(body.id)
                }

                if (body.label === 'ball') {
                    let down = this.app.screen.height - 70;
                    if (body.position.y >= down) {
                        this.container.removeChild(this.balls[body.id]);
                        World.remove(this.engine.world, body);

                        this.bocketAnimation.forEach((bocket, i) => {
                            this.bockets[bocket].y += 4
                            this.addEffect(this.bockets[bocket]);
                            wait(100).then(() => {
                                this.container.removeChild(this.explosion);
                                this.bockets[bocket].texture = PIXI.Texture.from('/assets/images/bocket.png');
                                this.bockets[bocket].y = this.app.screen.height - 45
                                this.bocketAnimation.splice(i, 1);
                                wait(500).then(() => {
                                    this.bockets[bocket].texture = PIXI.Texture.from('/assets/images/bocket_active.png');
                                })
                            })
                        })
                    }
                }
            });
        });
    }

    play() {
        this.addBall()
        this.started = true;
    }

    stop() {
        this.started = false;
    }

    addPlinko() {
        var n = 9
        for (let i = 0; i <= n; i++) {
            for (let j = 0; j <= i; j++) {
                var x = 0.495 + (j - (i / 2)) / (11);
                var y = (i + 1) / (n);
                if (i !== 0) {
                    let body = Bodies.circle(100 * x * 5, 400 * y - 40, 9, {
                        isStatic: true,
                        label: 'pin'
                    });
                    World.add(this.engine.world, body);
                    let pin = PIXI.Sprite.from('./assets/images/pin.png');
                    pin.x = body.position.x;
                    pin.y = body.position.y;
                    pin.width = 18;
                    pin.height = 18;
                    pin.anchor.set(0.5);
                    this.container.addChild(pin);
                }
            }
        }
    }

    addBocket() {
        for (var i = 0; i < 9; i++) {
            const width = 45,
                height = 30

            let body = Bodies.rectangle(59.5 + i * 47.5, this.app.screen.height - 45, width, height, {
                isStatic: true,
                label: 'bocket',
                score: this.scores[i]
            });
            World.add(this.engine.world, body);

            let bocket = PIXI.Sprite.from('/assets/images/bocket_active.png');
            bocket.x = body.position.x;
            bocket.y = body.position.y;
            bocket.width = width;
            bocket.height = height;
            bocket.anchor.set(0.5);
            bocket.zIndex = 2;
            bocket.interactive = true;
            bocket.score = this.scores[i]
            bocket.id = body.id;
            bocket.on('pointerover', e => this.onOver(bocket))
            bocket.on('pointerout', e => this.onOut(bocket))

            const style = new PIXI.TextStyle({
                fontFamily: 'tahoma',
                fontSize: 13,
                fill: '#000'
            });

            let score = new PIXI.Text(this.scores[i].toFixed(1) + 'x', style);
            score.x = -12;
            score.y = -7

            this.bockets[body.id] = bocket
            bocket.addChild(score)
            this.container.addChild(bocket);
        }
    }

    addBall() {
        let body = Bodies.circle(this.app.screen.width / 2 + rand(-1.5, 1.5), 10, 9, {
            restitution: 0.3,
            label: 'ball',
            friction: 0.1
        });
        World.add(this.engine.world, body);

        let ball = PIXI.Sprite.from('/assets/images/ball.png');
        ball.x = body.position.x;
        ball.y = body.position.y;
        ball.width = 35;
        ball.height = 35;
        ball.anchor.set(0.5);
        ball.zIndex = 1;
        ball.interactive = true;
        this.balls[body.id] = ball
        this.container.addChild(ball);
    }

    addEffect(bocket) {
        const explosionTextures = [];
        let i;

        for (i = 0; i < 5; i++) {
            const texture = PIXI.Texture.from(`Explosion_Sequence_A ${i + 1}.png`);
            explosionTextures.push(texture);
        }

        let id = bocket.id

        let rc = new PIXI.Container()

        this.explosion = new PIXI.AnimatedSprite(explosionTextures);
        this.explosion.x = bocket.x
        this.explosion.y = bocket.y
        this.explosion.anchor.set(0.5);
        this.explosion.gotoAndPlay(Math.random() * 27);
        rc.addChild(this.explosion);
        this.container.addChild(rc);

        // start animating
        this.app.start();

        wait(100).then(() => {
            rc.removeChild(this.explosion);
            this.container.removeChild(rc);
            this.explosion.alpha = 0
        })
    }

    onOut(e) {
        this.container.removeChild(this.popup);
    }

    onOver(e) {
        this.popup = new PIXI.Container();
        this.popup.sortableChildren = true;

        var x = 0

        if (e.id === 65 || e.id === 64 || e.id === 63 || e.id === 62) {
            x = -100
        } else
            if (e.id === 61) {
                x = -50
            }

        let g = new PIXI.Graphics();
        g.lineStyle(0);
        g.beginFill(0xFFFFFF, 1);
        g.drawRoundedRect(e.getBounds().x + x, e.getBounds().y - 40, 150, 25, 4);
        g.endFill();
        g.sortChildren(5);
        g.zIndex = 5

        const style = new PIXI.TextStyle({
            fontFamily: 'Tahoma',
            fontSize: 11,
            fill: '#111',
            dirty: true,
            align: 'center',
            textBaseline: 100,
            breakWords: true
        });

        let profit = (parseFloat(this.game_engine.amount) * e.score) - parseFloat(this.game_engine.amount);

        if (e.score === '0.50' || e.score === 0.50) {
            profit = parseFloat(this.game_engine.amount) / 2;
        } else if (e.score === '1.00' || e.score === 1.00) {
            profit = 0.00000000;
        }

        let t = new PIXI.Text('Win Amount: ' + forceSatoshiFormat(profit), style);
        t.x = e.getBounds().x + x + 10
        t.y = e.getBounds().y - 35
        t.sortChildren(5);
        t.zIndex = 5;

        this.popup.addChild(g, t)
        this.container.addChild(this.popup);
    }

    sendScore(score) {
        const e = this.game_engine;
        e.init = true;
        e.bonus = parseFloat(score);
        e.busted();
    }

    destroy() {
        this.app.ticker.stop()
        this.app = null;
        this.container = null;
    }

}


export default Game
